import * as React from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useSession } from 'next-auth/react';
import styled, { css, keyframes } from 'styled-components';
import FocusTrap from 'focus-trap-react';

import BREAKPOINTS from 'styles/breakpoints';
import { useNav } from 'context/NavProvider';
import { useClaysLocation } from 'context/ClaysLocationProvider';
import { formatEnumToString, formatSlug } from 'utils/helpers';
import FONTS from 'styles/fonts';
import signOut from 'utils/authentication/sign-out';

import BookButton from 'components/Button/BookButton';
import ClaysLogo from 'components/ClaysLogo';

import { AccountContext } from 'context/AccountProvider';

import zIndex from 'styles/zIndex';
import { CircleButton, DotsPattern } from 'styles/global';
import COLORS from 'styles/colors';

import { isEven } from 'utils/helpers';
import SearchBarMobile from './SearchBar/SearchBarMobile';
import SearchBarDesktop from './SearchBar/SearchBarDesktop';

import { observer } from 'mobx-react-lite';
import { navStore } from 'src/stores/NavStore';

const slideIn = keyframes`
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const MenuTransitionTime = '0.4s';

// NAV BAR STYLES
const PageNavBar = styled.nav`
  pointer-events: all;
  padding: 1.25rem 1.25rem 0 1.25rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  will-change: opacity;
  z-index: ${zIndex.navBar};
  transform: var(--landing-has-banner);
  transition: all ${MenuTransitionTime};

  ${BREAKPOINTS.medium`
  padding: 2rem 2rem 0 4rem;
  `}
`;

const Logo = styled(ClaysLogo)`
  position: fixed;
  top: 1.25rem;
  left: 1.25rem;
  transform: var(--landing-has-banner);
  z-index: ${zIndex.navBarLogo};

  ${BREAKPOINTS.medium`
  top: 2rem;
  left: 2rem;
  `}
  will-change: opacity;
  z-index: ${zIndex.navBarLogo};
  transition: transform ${MenuTransitionTime};

  ${({ isolate }) =>
    isolate &&
    css`
      mix-blend-mode: exclusion;
    `};

  ${({ modalIsOpen }) =>
    modalIsOpen &&
    css`
      visibility: hidden;
    `};

  &:hover {
    opacity: 0.7;
  }
`;

const LogoSpacer = styled.div`
  width: 50px;
`;

const BurgerMenuInner = css`
  position: relative;
  width: 45%;
  height: var(--bar-height);
  background: ${COLORS.white};
  transition: ${MenuTransitionTime};

  &:nth-of-type(1) {
    transform: translateY(calc(-1 * var(--bar-spacing) / 2));
  }

  &:nth-of-type(2) {
    transform: translateY(0);
  }

  &:nth-of-type(3) {
    transform: translateY(calc(var(--bar-spacing) / 2));
  }

  ${({ burgerMenuIsOpen }) =>
    burgerMenuIsOpen &&
    css`
      &:first-of-type {
        transform: translateY(80%) rotate(45deg) translateX(2%);
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:last-of-type {
        transform: translateY(-80%) rotate(-45deg) translateX(2%);
      }
    `};
`;

const BurgerButton = styled.button`
  --button-size: 50px;
  --bar-height: 2px;
  --bar-spacing: calc(var(--button-size) * 0.15);

  ${CircleButton}
  width: var(--button-size);
  height: var(--button-size);
  flex-direction: column;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  z-index: ${zIndex.burgerMenu};

  ${({ burgerMenuIsOpen }) =>
    burgerMenuIsOpen &&
    css`
      border-color: ${COLORS.pinkMillennial.opacity(0.4)};
    `}

  div {
    ${BurgerMenuInner}
  }

  ${BREAKPOINTS.medium`
    --button-size: 80px;
    --bar-height: 3px;
  `}

  &:hover {
    opacity: 0.7;
  }

  &:focus-visible {
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

const SignOutBurgerSection = styled.div`
  display: flex;
  gap: 22px;
`;

const SignOutButton = styled.button`
  font-family: ${FONTS.poppins};
  font-weight: 400;
  font-size: 0.875rem;
  font-weight: 600;
  color: ${COLORS.white};
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }

  ${BREAKPOINTS.small`
  font-size: 16px;
  `}
`;

// MODAL STYLES

const Modal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;

  display: none;
  flex-direction: column;

  ${BREAKPOINTS.small`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `}

  padding: 6.69rem 2.8rem;

  background: ${COLORS.forest};
  clip-path: circle(0% at calc(100vw - 45px) 45px);
  transition: all ${MenuTransitionTime};
  z-index: ${zIndex.mobileNavOverlay};
  ${DotsPattern}

  ${({ burgerMenuIsOpen }) =>
    burgerMenuIsOpen &&
    css`
      display: flex;
      clip-path: circle(100%);
      pointer-events: initial;
    `};
`;

// Link styles

const AnimatedLink = styled.li`
  opacity: 0;
  transform: ${({ reverse }) =>
    reverse ? 'translateX(-200px)' : 'translateX(200px)'};

  &:last-of-type {
    padding: 0;
  }

  ${({ show, delay }) =>
    show &&
    css`
      animation: ${slideIn} 0.8s cubic-bezier(0.34, 1.56, 0.64, 1)
        ${delay + 100}ms forwards;
    `}
`;

const LinkStyled = styled.a`
  text-decoration: none;
  transition: ${MenuTransitionTime};
  font-family: ${FONTS.wulkan};
  font-feature-settings: 'ss03' on;
  font-weight: 850;
  color: ${COLORS.pinkMillennial};

  &:hover {
    opacity: 0.7;
  }

  &[data-disabled='true'] {
    opacity: 0.3;
    pointer-events: none;
  }
`;

const NavLink = ({ href, children, disabled = false, ...props }) => {
  if (disabled) {
    return (
      <LinkStyled data-disabled={true} {...props}>
        {children}
      </LinkStyled>
    );
  }

  return (
    <LinkStyled href={href} {...props} passHref>
      {children}
    </LinkStyled>
  );
};

const SiteLink = styled(AnimatedLink)`
  font-size: 2.375rem;
  line-height: 3.125rem;
  letter-spacing: -0.0475rem;

  ${BREAKPOINTS.large`
      font-size: 5rem;
      line-height: 100%;
      letter-spacing: -0.1rem;
    `}
`;

const SiteLinks = styled.ul`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 0.5rem;
`;

const LocationLink = styled(AnimatedLink)`
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  line-height: 1.875rem;
  letter-spacing: -0.03rem;

  ${BREAKPOINTS.large`
  font-size: 2.125rem;
  line-height: 2.70338rem;
  letter-spacing: -0.04325rem;
`}

  & span {
    color: ${COLORS.white};
    font-family: ${FONTS.poppins};
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    opacity: 0.3;

    ${BREAKPOINTS.large`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
    `}
  }
`;

const LocationsHeading = styled.h4`
  color: ${COLORS.white};
  font-family: ${FONTS.poppins};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  opacity: 0.8;

  ${BREAKPOINTS.large`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
    `}
`;

const LocationsLinks = styled.ul`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
`;

const LineDivider = styled.div`
  display: none;
  width: 2px;
  background-color: ${COLORS.white};
  height: 100%;
  opacity: 0.05;

  ${BREAKPOINTS.small`
    display: block;
  `}
`;

const CircleDividerSize = '0.5rem';

const CircleDivider = styled.img`
  height: ${CircleDividerSize};
  width: ${CircleDividerSize};
  transition: all 0.8s;
  margin-top: 2rem;
  margin-bottom: 2rem;
  opacity: 0;

  ${({ burgerMenuIsOpen }) =>
    burgerMenuIsOpen &&
    css`
      opacity: 1;
    `}

  ${BREAKPOINTS.small`
      display: none;
    `}
`;

const BookingButton = styled(BookButton)`
  align-self: center;

  &:focus {
    outline: 1px solid ${COLORS.pinkMillennial};
  }
`;

const SearchBarContainer = styled.div`
  ${BREAKPOINTS.medium`
    display: none;
  `}
`;

const DesktopSearchBarContainer = styled.div`
  display: none;
  ${BREAKPOINTS.medium`
    display: block;
  `}
`;

const Menu = ({ burgerMenuIsOpen, siteLinks }) => {
  const { allClaysLocations } = useClaysLocation();
  return (
    <Modal burgerMenuIsOpen={burgerMenuIsOpen}>
      <SiteLinks>
        {siteLinks.map(({ label, href }, i) => (
          <SiteLink
            key={i}
            show={burgerMenuIsOpen}
            delay={(i + 1) * 100}
            reverse={isEven(i + 1)}
          >
            <NavLink href={href}>{label}</NavLink>
          </SiteLink>
        ))}
      </SiteLinks>

      <LineDivider />

      <CircleDivider
        burgerMenuIsOpen={burgerMenuIsOpen}
        src="/images/icons/circle.svg"
        alt="Circle menu divider"
      />

      <LocationsLinks>
        <LocationsHeading>Locations</LocationsHeading>
        {allClaysLocations.map(({ venueLocation, comingSoon }, i) => (
          <React.Fragment key={venueLocation}>
            <LocationLink
              show={burgerMenuIsOpen}
              delay={(siteLinks.length + i + 1) * 100}
              reverse={isEven(siteLinks.length + i + 1)}
            >
              <NavLink href={`/${formatSlug(venueLocation)}`}>
                {formatEnumToString(venueLocation)}
              </NavLink>
              {comingSoon && <span>Coming soon</span>}
            </LocationLink>
          </React.Fragment>
        ))}
      </LocationsLinks>

      <CircleDivider
        burgerMenuIsOpen={burgerMenuIsOpen}
        src="/images/icons/circle.svg"
        alt="Circle menu divider"
      />

      <BookingButton size="medium" />
    </Modal>
  );
};

const NavBar = () => {
  const [isClient, setIsClient] = React.useState(false);
  const { events } = useRouter();
  const { data: session } = useSession();
  const { setUser } = React.useContext(AccountContext);

  const {
    toggleBurgerMenu,
    burgerMenuIsOpen,
    setBurgerMenuIsOpen,
    modalIsOpen,
    isolate,
  } = useNav();

  React.useEffect(() => {
    setIsClient(true);
    const closeMenuOnRouteChange = () => {
      setBurgerMenuIsOpen(false);
    };

    events.on('routeChangeComplete', closeMenuOnRouteChange);
    return () => {
      events.off('routeChangeComplete', closeMenuOnRouteChange);
    };
  }, [events, setBurgerMenuIsOpen]);

  const [disableFocusTrap, setDisableFocusTrap] = React.useState(false);

  const signOutUser = () => {
    signOut();
    setUser(null);
  };

  const siteLinks = [
    {
      label: 'Home',
      href: `/`,
    },
    {
      label: 'Events & Packages',
      href: `/events-and-packages`,
    },
    { label: 'Food & Drink', href: `/food-and-drink` },
    { label: 'The Games', href: `/games` },
  ];

  return (
    <>
      <FocusTrap active={burgerMenuIsOpen}>
        <div>
          <Logo modalIsOpen={modalIsOpen} isolate={!isolate} />
          <PageNavBar aria-label="Main navigation">
            <LogoSpacer />
            {!burgerMenuIsOpen && isClient && navStore.searchBarVisible() && (
              <>
                <SearchBarContainer>
                  <SearchBarMobile />
                </SearchBarContainer>
                <DesktopSearchBarContainer>
                  <SearchBarDesktop />
                </DesktopSearchBarContainer>
              </>
            )}
            <SignOutBurgerSection>
              {session && burgerMenuIsOpen && (
                <SignOutButton as="button" onClick={signOutUser}>
                  Sign out
                </SignOutButton>
              )}

              <BurgerButton
                aria-label="Toggle burger menu"
                burgerMenuIsOpen={burgerMenuIsOpen}
                onClick={toggleBurgerMenu}
              >
                <div />
                <div />
                <div />
              </BurgerButton>
            </SignOutBurgerSection>
          </PageNavBar>
          <Menu
            burgerMenuIsOpen={burgerMenuIsOpen}
            siteLinks={siteLinks}
            session={session}
            signOutUser={signOutUser}
          />
        </div>
      </FocusTrap>
    </>
  );
};

export default observer(NavBar);
